.card{
    background-color: transparent;
    border:none;
    width: 250px;
    display: inline-block;
    text-align: center;
    color: var(--teal);

}

.card img{
    width: 10vw;
    /* filter: drop-shadow( 2px 2px var(--darker-gray)); */
}