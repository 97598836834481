@media (min-width:360px) {
    #footerDiv .row{
        text-align: center;
    }

    .footer-right{
        padding-top: 25px !important;
    }

    #footerDiv{
        width: 90%;
    }
}

@media (min-width:576px) {
    .footer-right{
        text-align: right;
        padding-top: 0 !important;
    }

    #footerDiv{
        width: 80%;
    }

    #footerDiv .row{
        text-align: left;
    }
}

#footerDiv{
    margin: 0 auto;
    /* font-size: var(--p-small); */
    padding: 20px 0;
}

#footerDiv .row{
    margin: 0
}

#footerDiv .col{
    padding: 0;
}

#footerDiv ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#footerDiv a{
    text-decoration: none;
}

#footerDiv i{
    font-size: var(--p-mid);
}

#copyrightDiv{
    margin: 0 auto;
}

#copyrightDiv p{
    margin-bottom: 0;
}

/* Pages */
.footer-home{
    border-top: 1px solid var(--yellow) ;
    color: var(--yellow);
}
.footer-home a{
    color: var(--yellow);
}

.footer-thankYou{
    border-top: 1px solid var(--teal) ;
    color: var(--teal);
}
.footer-thankYou a{
    color: var(--teal);
}

.footer-services{
    border-top: 1px solid var(--light-teal) ;
    color: var(--light-teal);
}

.footer-services a{
    color: var(--light-teal)
}

.footer-portfolio{
    border-top: 1px solid var(--lighter-teal) ;
    color: var(--lighter-teal);
}

.footer-portfolio a{
    color: var(--lighter-teal)
}

.footer-contact{
    border-top: 1px solid var(--yellow) ;
    color: var(--yellow);
}

.footer-contact a{
    color: var(--yellow)
}