#headerDiv{
    justify-content: center;
    align-items: center;
    margin:0
}

#titleDiv{
    width: 100%;
}

#titleDiv p{
    margin-bottom: 0;
}

.title{
    margin: 0 5px;
    text-shadow: 3px 3px var(--darker-gray);
    font-weight: 300;
}

#subtitleDiv{
    text-shadow: 1px 1px var(--darker-gray);
    color: var(--lighter-yellow);
 }

#computer{
    opacity: 0;
}

#crown{
    width: 75px;
    opacity: 0;
    filter:drop-shadow(2px 2px var(--darker-gray));
}

#computerDiv{
    position: relative;
}

#computerTextDiv{
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);
    color: var(--darker-gray);
    z-index: 2;
    position: absolute;
    font-size: 40px;
}

#computerTextDiv p{
    margin-bottom: 0;
}

#recentDiv{
    text-align: right;
}

/*** Media Queries ***/
@media (min-width:360px) {
    #computer{
        width:175px;
    }

    .title{
        font-size: 30px;
    }

    #subtitleDiv{
        font-size: 20px;
    }

    #recentDiv{
        padding: 100px 10px 0 0;
    }

    #recentDiv span{
        display: block;
    }
}

@media (min-width:576px) {
    #computer{
        width:200px;
    }

    #computerTextDiv span{
        padding: 0 5px;
    }

    .title{
        font-size: 50px;
    }

    #subtitleDiv{
        font-size: 30px;
    }

    #recentDiv{
        font-size: 20px;
        padding: 50px 10px 0 0;
    }
}

@media (min-width:768px) {
    #recentDiv{
        margin-right: 10%;
    }

    #recentDiv span{
        display: inline;
    }
}