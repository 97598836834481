.navbar{
margin: 0  auto;
padding: 5px 0;
}

.toggle, .toggle:focus, .toggle:active, .toggle:hover{
    background-color: transparent !important;
    border: none;
    font-size: x-large;
}

/*** Page specific styles ***/
/* Home & Contact */
.navbar-home, .navbar-contact{
    border-bottom: 1px solid var(--yellow);
}

.toggle-home:focus{
    color: var(--yellow) !important;
}

.tagline-home, .tagline-contact{
    color: var(--yellow);
}

.toggle-home, .toggle-contact{
    color: var(--yellow);
}

/* Services */
.navbar-services{
    border-bottom: 1px solid var(--light-teal);
}

.toggle-services:focus, .toggle-services:hover, .toggle-services:active{
    color: var(--light-teal) !important;
}

.tagline-services{
    color: var(--light-teal);
}

.toggle-services{
    color: var(--light-teal);
}

/* Portfolio */
.navbar-portfolio{
    border-bottom: 1px solid var(--lighter-teal);
}

.tagline-portfolio{
    color: var(--lighter-teal);
}

.toggle-portfolio{
    color: var(--lighter-teal);
}

/* Thank You */
.navbar-thankYou{
    border-bottom: 1px solid var(--teal);
}

.toggle-thankYou{
    color: var(--teal);
}
/*** Navigation Modal ***/
#navModal .modal-content{
    background-color: var(--lighter-yellow);
}

#navModal .modal-header{
    border-bottom: none;
    padding: 0
}

#navModal .modal-body{
    padding: 0;
}

#navModal .btn-close{
    background: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23dab600%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>');
    width: 0.1em;
    height: 0.1em;
    opacity: 1;
    margin-right:10px;
}
/*** Media Queries ***/
@media (min-width:360px) {
    .navbar{
        width: 90%;
    }

    .tagline{
        display: none;

    }

    .logo{
        width: 70px
    }

    #navModal .modal-content{
        padding: 10px;
    }
}

@media (min-width:576px){
    #navModal .modal-content{
        padding: 2% 15%;
    }
}

@media (min-width:768px) {
    .navbar{
            width: 70%;
    }

    .tagline{
        display: inline-block;
        font-size: 14px
    }

    .logo{
        width:100px
    }
}
