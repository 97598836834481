#sliderWrapper{
    min-height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0
}

.slide-div{
    height: 70vh;
    width: 80vw;
}

.slide-div img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.slick-prev, .slick-next{
    bottom: -50px !important;
    top: auto !important;
    margin-bottom: 0 !important
}

.slick-prev{
    left: 45% !important
}

.slick-next{
    right: 45% !important
}

.video-wrapper{
    width: 50px !important;
    height: 50px !important;
    margin: 0 !important;
    
}