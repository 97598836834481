@media (min-width:360px) {
  #quote {
    justify-content: center;
    align-items: center;
  }
}

@media (min-width:992px) {
  #quote {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    display: flex;
  }

  .bar{
    width: 5vw;
    height: 105vh;
    background-color: var(--darker-gray);
  }

  #quote{
    position: fixed;
    width:100%;
    height: 100%;
    display: flex;
    z-index: 5;
    color: var(--teal);
    padding: 2vh 4vw;
    font-size: 8vw;
  }