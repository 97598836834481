@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&family=Barrio&family=Big+Shoulders+Display:wght@100..900&family=Bungee+Outline&family=Karantina:wght@300;400;700&family=Londrina+Outline&family=Londrina+Shadow&family=Londrina+Sketch&family=Londrina+Solid:wght@100;300;400;900&family=Monoton&family=Poiret+One&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Rubik+Doodle+Shadow&family=Rubik+Vinyl&family=Tourney:ital,wght@0,100..900;1,100..900&family=Barriecito&family=Inter+Tight&family=Six+Caps&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  color: var(--light-gray);
  background-color: var(--darker-gray);
}

/* Custom properties */
:root{
  /* Colors */
  --darker-gray: #0a0a0a;
  --dark-gray: #222222;
  --gray: #848484;
  --light-gray: #e6e6e6;
  --lighter-gray: #ededed;
  --yellow: #dab600;
  --light-yellow: #ecda7f;
  --lighter-yellow: #f9f3d8;
  --lightest-yellow: #fefdfb;
  --dark-teal: #006666;
  --teal: #008080;
  --light-teal: #66b2b2;
  --lighter-teal: #cce5e5;

  /* Font sizes*/
  --p-xlarge: 26px;
  --p-large: 20px;
  --p-mid: 16px;
  --p-small: 14px;
  --p-xsmall: 12px;
}
/* Font families*/
.barriecito-regular {
  font-family: "Barriecito",  "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.glacial-indifference {
  font-family: "GlacialIndifferenceRegular", sans-serif;
}

.inter-tight{
  font-family: 'Inter Tight', sans-serif;
}

.six-caps{
  font-family: "Six Caps", sans-serif;
}

.montserrat{
  font-family: "Montserrat", sans-serif;
}

@media (min-width:360px) {
  h1{
    font-size: 30px;
  }

  h2{
    font-size: 25px;
  }

  h3{
    font-size: 20px;
  }

  h4{
    font-size: 18px;
  }

}

@media (min-width:576px) {
  .special-cursor{
    font-size: 50px;
  }
}

@media (min-width:768px) {
  h1{
    font-size: 40px
  }

  h2{
    font-size: 30px;
  }

  h3{
    font-size: 25px;
  }

  h4{
    font-size: 20px;
  }

  .special-cursor{
    font-size: 60px
}
  :root{
    --p-xlarge: 26px;
    --p-large: 20px;
    --p-mid: 16px;
    --p-small: 14px;
    --p-xsmall: 12px;
  }
}

@media (min-width:992px) {
  .special-cursor{
    font-size: 70px
}
}

@media (min-width:1200px) {
  .special-cursor{
    font-size: 80px
}
}


/* Elements */
/** Text Elements **/
p{
  font-family: "Roboto Condensed", sans-serif;
}

h1, h2, h3, h4{
  font-family: "Londrina Solid", sans-serif;
  font-style: normal;
}

h2, h3, h4{
  font-weight: 300;
}
h1 :hover {
    text-shadow: 1px 1px var(--darker-gray);

}

a{
  color: var(--teal);
}

/** Custom text classes **/
.p-xlarge{
  font-size: var(--p-xlarge);
}

.p-large{
  font-size: var(--p-large);
}

.p-mid{
  font-size: var(--p-mid);
}

.p-small{
  font-size: var(--p-small);
}

.p-xsmall{
  font-size: var(--p-xsmall);
}

.darker-gray{
  color: var(--darker-gray);
}

.dark-gray{
  color: var(--dark-gray);
}

.gray{
  color: var(--gray)
}

.yellow{
  color: var(--yellow)
}

.light-yellow{
  color: var(--light-yellow);
}

.lighter-yellow{
  color: var(--lighter-yellow)
}
.lighter-teal{
  color: var(--lighter-teal)
}
.light-teal{
  color: var(--light-teal)
}

.teal{
  color: var(--teal)
}

.bold{
  font-weight: bold;
}
.section-header{
  letter-spacing: 3px;
  font-size: 16px;
  /* font-family: "Roboto Condensed", sans-serif; */
  margin-bottom: 50px;
}

.no-shadow{
  text-shadow: none;
}

.jumbo-text-bubble{
  font-family: "Londrina Shadow", "Londrina Sketch", "Rubik Doodle Shadow", sans-serif;
  /* text-shadow: 1px 1px var(--darker-gray); */
}

.jumbo-text-solid{
  font-family: "Londrina Solid", sans-serif;
}

/** Scrollbar **/
::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-track{
  background-color: var(--dark-gray);
  /* background: rgba(0, 0, 0, 0); */
}

::-webkit-scrollbar-thumb{
  background-color: var(--light-yellow);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background-color:var(--lighter-yellow)
}

/** Buttons **/
.primary-btn{
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid var(--yellow);
  color: var(--yellow);
  font-weight: bold;
  font-size: var(--p-large);
}

.primary-btn:hover{
  background-color: var(--yellow);
  color: var(--darker-gray);
  border: 1px solid var(--yellow);
}

/** HYPERLINKS **/

.special-cursor a{
  transition: all 500ms;
  width: fit-content;
  margin: 0 auto;
  padding: 0;
  text-decoration: none;
  display: inline-block;
  color: var(--lighter-yellow);
}

.special-cursor a:hover {
  transform: scale(1.05) !important;
  cursor: url("./assets/cursor-1.png"), pointer;
  color: var(--yellow) !important;
  text-shadow: 1px 1px var(--darker-gray);
}

.special-cursor a:hover::after{
  opacity: 1;
  transform: scale(1);
}

/* Custom classes */
.vertical-center{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.page-header{
  min-height: calc(100vh - 75px);
  width: 100%;
}

.content{
  padding: 0 10%;
}

.no-margin {
  margin: 0 !important
}

.no-padding{
  padding: 0 !important;
}

.left{
  text-align: left !important;
}

.right{
  text-align: right !important;
}

.center{
  text-align: center !important;
}