#navModalDiv{
    position: relative;
    color: var(--teal);
    border-top: 1px solid var(--light-yellow);
    border-bottom: 1px solid var(--light-yellow);
    text-align: center;
}

#navModalDiv a, #navModalDiv h1{
    color: var(--yellow) !important;
    text-decoration: none;
}

#navModalDiv a:hover{
    color: var(--light-yellow);
}

#navModalFooter{
    color: var(--yellow);
    margin: 25px auto;
}

#navModalFooter i{
    /* margin-right: 50px; */
    color: var(--yellow);
}

#navModalDiv .special-cursor a:hover{
    color: var(--light-yellow) !important;
}

@media (min-width:360px) {
    #navModalDiv{
        height: calc(100vh - 165px);
    }

    .resume-link{
        margin-bottom: 25px
    }

    .top-bordered-col{
        border-top: 1px solid var(--light-yellow);
        padding-top: 25px;
        width: 70%;
        margin: 0 auto;
    }

    #navModalFooter{
        text-align: center;
        font-size: 25px;
    }
}

@media (min-width:576px) {
    #navModalDiv{
        height: calc(100vh - 180px);
    }

    .right-bordered-col{
        border-right: 1px solid var(--light-yellow);
    }

    .top-bordered-col{
        border: none;
        width: 100%;
        padding: 0
    }

    .resume-link{
        margin-top: 25px;
        margin-bottom: 0;
    }

}

@media (min-width:768px) {
    #navModalDiv{
        height: calc(100vh - 210px);
    }

    #navModalFooter{
        font-size: 30px;
    }
}

@media (min-width:992px) {
   #navModalDiv h1{
        font-size:45px
    }
}

@media (min-width:1200px) {
    #navModalDiv{
        height: calc(100vh - 275px);
    }

    #navModalFooter{
        font-size: 35px;
    }
}