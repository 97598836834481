@media (min-width:360px) {
    #portfolio{
        padding: 1% 5%;
    }

    .project-about-div{
        min-height: 50vh;
    }

    .logo-img{
        max-width: 300px;
        margin-bottom: 100px
    }

    .project-header{
        font-size: 7vw;
        line-height: 7vw;
        font-family: "Londrina Solid", sans-serif;
        text-align: left;
    }

    .tech-table-heading{
        text-align: left;
    }
}

@media (min-width:576px) {
    .logo-img{
        max-width: 500px;
    }

    .project-header{
        font-size: 5vw;
        line-height: 5vw;
        text-align: right;
    }

    .tech-table-heading{
        text-align: right;
    }

    .typography-left{
        text-align: right;
    }

    .typography-right{
        border-left: 1px dotted var(--teal);
    }
}

@media (min-width:768px) {
    #portfolio{
        padding: 1% 15%;
    }

    .project-about-div{
        min-height: 100vh;
    }


    .logo-img{
        max-width: 700px;
    }
}

@media (min-width:992px) {
    .logo-img{
        max-width: 800px;
    }
}

#portfolio{
    color:var(--lighter-teal)
}

#portfolioNav .nav-link{
    margin: 0;
}

#portfolioNav{
    width: 100%;
    border-bottom: 1px solid var(--light-teal);
}

#portfolioNav .nav-link, #portfolioNav .nav-link:visited{
    color: var(--light-teal);
}

#portfolioNav .nav-link:hover{
    font-weight:bold;
}

#portfolioNav .nav-link:active, #portfolioNav .nav-link.active{
    background-color: var(--light-teal);
    font-family: "Londrina Solid", sans-serif;
    color: var(--dark-teal);
}

.tab-content{
    padding: 10px 0;
}

.tab-content .active:not(.carousel-indicators .active, .carousel-item) {
    padding: 0;
    border: 2px solid var(--teal);
}

.tab-content p{
    white-space: pre-wrap;
}

/* Carousel Screenshots */
.carousel-div{
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    padding-bottom: 30px;
}

.carousel-img{
    object-fit: contain;
    width: 90%;
    max-width: 1000px;
    padding-bottom: 50px;
}

button.react-multiple-carousel__arrow{
    bottom: 0;
    background-color: var(--lighter-teal);
}

.react-multiple-carousel__arrow::before{
    color: var(--teal) !important;
}
button.react-multiple-carousel__arrow:hover{
    background: rgba(34,34,34,0.7);
    color: var(--light-gray) !important;
    border: none;
}
.react-multiple-carousel__arrow:hover::before{
    color: var(--light-gray) !important
}

.react-multiple-carousel__arrow--right{
    right: 35% !important;
}
.react-multiple-carousel__arrow--left{
    left: 35% !important;
}
.project-design-narrative-div{
    padding-top:50px;
}

/* Project Info */
.project-info-div i{
    margin-right: 5px;
}

.project-title, .project-subtitle{
    font-weight: 300;
    font-family: "Londrina Solid", sans-serif;
}

.project-title{
    font-size: 8vw;
    text-shadow: 2px 2px var(--dark-teal);
    line-height: 8vw;
    padding: 50px 0 0 0;
    margin-bottom: 0
}

.project-subtitle{
    font-size: 4vw;
    margin-bottom: 50px;
}

.project-summary-div{
    color: var(--light-gray);
}

.project-summary-div a{
    color: var(--light-gray);
    text-decoration: none;
}

.project-summary-div a:hover{
    font-weight: bold;
}

.project-about-div{
    align-items: center;
    justify-content: center;
}

.project-technical-div{
    border-top: 1px dashed var(--light-teal);
    padding: 50px 0;
    align-items: center;
}

.project-design-div{
    border-top: 1px dashed var(--light-teal);
    padding: 50px 0;
    text-align: left;
}
.project-typography-div{
    background-color: var(--light-teal);
    margin: 0 auto 20px auto;
    border: 1px solid var(--light-teal);
    width: 90%;
    padding: 25px;
}

/** Tech Table**/
.tech-table-div{
    border: 1px solid var(--dark-teal);
    background-color: var(--light-teal);
    width: max-content;
    color: var(--dark-gray);
    margin-bottom: 20px;
    max-width: 100%;
}

.tech-table-div p{
    margin: 0
}

.tech-table-div .row{
    align-items: center;
    padding: 3px 0;
    margin: 0
}

.tech-table-heading{
    font-weight: bold;
}

.bordered-row{
    border-top: 1px dotted var(--teal);
}

.tech-icon-div{
    height: 100%;
    width: 100px;
    text-align: center;
    border-radius: 50%;
    padding: 1px;
    margin-right:10px;
    align-items: center;
}

.tech-icon{
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.application-diagram-link{
    text-decoration: none;
    padding: 10px;
    border-radius: 0;
    font-weight: normal;
    cursor: pointer;
}

.application-diagram-link:hover{
    font-weight: bold;
}

.application-diagram-img{
    width: 90%;
    max-width: 1000px;
    object-fit:contain;
    margin: 0 auto;
}

.color-swatch-div{
    border: 2px solid var(--teal);
    color: var(--lighter-teal);
}

.color-swatch{
    background-color: var(--teal);
    width: fit-content;
    padding: 2px;
}