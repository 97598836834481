@media (min-width:360px) {

#contactMsg{
    text-align: center;
}

#formContainer{
    width: 90%;
}
}

@media (min-width:576px) {

    #contactMsg{
        text-align: right;
    }

    #formContainer{
        width: 60%;
    }
}

#contact, #thankYou{
    min-height: 100vh;
}

.contactHeaderDiv{
    width: 70%;
    margin: 0 auto;
    text-align: left;
}
.contactHeader{

    font-size: 15vw;
    line-height: 14vw;
}

#ashContactImg{
    margin: 50px 0;
    object-fit: contain;
    max-width: 100%;
}

#contact h1{
    color: var(--yellow);
    text-decoration: underline;
    text-decoration-color: var(--yellow);
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
}

#formContainer{
    margin: 20px auto;
    border-radius: 5px;
}

#contactForm .form-label{
    color: var(--yellow);
    font-weight: bold;
    margin: 0
}

#contactForm .form-control{
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid var(--yellow);
    border-radius: 0;
    color: var(--dark-gray);
}

#contactForm .form-control:focus, #contactForm textarea{
    border: 2px solid var(--yellow) !important;
    box-shadow: none;
}

#contactForm textarea {
    border: 1px solid var(--yellow) !important;
}